import m from 'mithril';

// DI container
import { DiContainer } from './di';

// Component imports
import { NavbarView } from './views/components/NavbarView';
import { FooterView } from './views/components/FooterView';
import { SearchBarView } from './views/components/SearchBarView';

// Page imports
import { IndexPage } from './views/pages/Index.page';
import { AboutPage } from './views/pages/About.page';
import { FeedbackPage } from './views/pages/Feedback.page';
import { PolicyThemePage } from './views/pages/PolicySector.page';
import { SearchResultPage } from './views/pages/SearchResult.page';
import { PolicyDetailPage } from './views/pages/PolicyDetail.page';
import { AddPolicyPage } from './views/pages/AddPolicy.page';

/**
 * Feed an Awilix DI container to a Mithril.js component
 * @param {Object} comp - Mithril.js component
 * @param {Object} di - Awilix DI contiainer
 * @returns Mithril.js RouteResolver
 */
 function wrapBase(mainPageComponent, wrapperClasses = '', navActiveIdx = 0) {
    return m('div', {class: wrapperClasses}, [
        m('header', [
            m(NavbarView, {activeIdx: navActiveIdx}),
        ]),
        [
            mainPageComponent,
        ],
        m(FooterView),
    ]);
}

export function getRoutes(di) {
    const searchBarViewPresenter = di.resolve('searchBarViewPresenter');

    return {
        '/': {
            onmatch: async(route_args) => {
                try {
                    await Promise.all([
                        di.resolve('indexPresenter')
                            .fetchRandomPolicy(5),
                        di.resolve('indexPresenter')
                            .fetchSectorCounts(),
                    ]);
                }
                catch(err) {
                    // Cancel
                    console.error(err);
                    return new Promise(function() {});
                }
            },
            render: () => {
                const presenter = di.resolve('indexPresenter');
                const policyObj = presenter.featuredPolicies;
                const sectorCounts = presenter.sectorCounts;
    
                document.title = `UPRI Sourcebook`;
                
                return wrapBase(m(IndexPage, {
                    presenter,
                    searchBarPresenter: searchBarViewPresenter,
                    randomPolicies: policyObj,
                    sectors: sectorCounts
                }), 'body-wrapper-bg', 1);
            }
        },
        '/about': {
            render: () => {
                document.title = `About | UPRI Sourcebook`;
                return wrapBase(m(AboutPage), '', 0);
            }
        },
        '/feedback': {
            render:() => {
                const presenter = di.resolve('feedbackPresenter');
    
                document.title = `Feedback | UPRI Sourcebook`;
                return wrapBase(m(FeedbackPage, {presenter}), '', 2);
            }
        },
        '/policy/add': {
            render: () => {
                document.title = `Add Policy | UPRI Sourcebook`;
                return wrapBase(m(AddPolicyPage), '', 1);
            }
        },
        '/policy/:policyId': {
            onmatch: async (route_args) => {
                try {
                    await di.resolve('policyDetailPresenter')
                        .fetchPolicy(route_args.policyId);
                }
                catch(err) {
                    // Cancel
                    return new Promise(function() {});
                }
            },
            render: () => {
                const presenter = di.resolve('policyDetailPresenter');
    
                document.title = `${presenter.policyObj.title} | UPRI Sourcebook`;
                return wrapBase(m(PolicyDetailPage, {policyObj: presenter.policyObj}), '', 1);
            }
        },
        '/sector': PolicyThemePage,
        '/sector/:policySectorId': PolicyThemePage,
        '/search': {
            onmatch: async (route_args) => {
                try {
                    await di.resolve('searchResultPresenter')
                        .doInitSearch(route_args);
                }
                catch(err) {
                    // Cancel
                    console.error(err);
                    return new Promise(function() {});
                }
            },
            render: (vnode) => {
                const searchResults = di.resolve('searchResultPresenter').searchResults;
    
                document.title = `${vnode.attrs.q} | UPRI Sourcebook`;
    
                return wrapBase(m(SearchResultPage,
                    {
                        key: vnode.attrs.q,
                        presenter: di.resolve('searchResultPresenter'),
                        searchBarPresenter: searchBarViewPresenter,
                        results: searchResults,
                        kwords: vnode.attrs.q,
                        offset: Number(vnode.attrs.offset) || 0,
                        limit: Number(vnode.attrs.limit) || 10
                    }), '', 1);
            },
        },
    };
}