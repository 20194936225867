import { buildSearchFilterQuery, parseFilterTags } from '../util/SearchFilterParser';

export class SearchBarViewPresenter {
    constructor({varRepository}) {
        this.varRepository = varRepository;
    }

    async fetchSectors() {
        const res = await this.varRepository.getSectors();

        return Object.entries(res).map((v) => v[1]);
    }

    async fetchThemes() {
        const res = await this.varRepository.getThemes();
        
        return Object.entries(res).map((v) => v[1]);
    }

    async fetchDoctypes() {
        const res = await this.varRepository.getDoctypes();

        return Object.entries(res).map((v) => v[1]);
    }

    async fetchGeoscopes() {
        const res = await this.varRepository.getGeoscopes();

        return Object.entries(res).map((v) => v[1]);
    }

    diffSearchBarTags(prevVal, filterTags={gs, dtyp, thm, sec}) {
        const filterTagsStr = buildSearchFilterQuery({...filterTags, tagsSep: ' '});
        const {q} = parseFilterTags(prevVal);

        return q + ' ' + filterTagsStr;
    }

    getFiltersFromText(text) {
        const {f} = parseFilterTags(text);

        return f;
    }
}