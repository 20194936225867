import * as Awilix from 'awilix';

import { IndexPresenter } from './controllers/Index.presenter';
import { SearchResultPresenter } from './controllers/SearchResult.presenter';
import { PolicyDetailPresenter } from './controllers/PolicyDetail.presenter';
import { FeedbackPresenter } from './controllers/Feedback.presenter';

import { NetworkApiService } from './services/NetworkApiService';
import { JsonServerService } from './services/JsonServerService';
import { PolicyRepository } from './repositories/PolicyRepository';
import { VarRepository } from './repositories/VarRepository';
import { FeedbackRepository } from './repositories/FeedbackRepository';
import { SearchBarViewPresenter } from './controllers/SearchBarView.presenter';

function buildDiContainer() {
    const diContainer = Awilix.createContainer();

    // Values
    diContainer.register({
        apiUrl: Awilix.asValue(process.env.API_URL)
    });

    // Data sources/Services
    diContainer.register({
        dataSource: Awilix.asClass(NetworkApiService)
            .inject(() => ({apiPrefix: diContainer.resolve('apiUrl')}))
            .singleton(),
    });
    
    // Repositories
    diContainer.register({
        policyRepository: Awilix.asClass(PolicyRepository)
            .inject(() => ({dataSource: diContainer.resolve('dataSource')}))
            .singleton(),
        varRepository: Awilix.asClass(VarRepository)
            .inject(() => ({dataSource: diContainer.resolve('dataSource')}))
            .singleton(),
        feedbackRepository: Awilix.asClass(FeedbackRepository)
            .inject(() => ({dataSource: diContainer.resolve('dataSource')}))
            .singleton(),
    });

    // Presenters
    diContainer.register({
        indexPresenter: Awilix.asClass(IndexPresenter)
            .inject(() => ({policyRepository: diContainer.resolve('policyRepository'), varRepository: diContainer.resolve('varRepository')}))
            .singleton(),
        searchResultPresenter: Awilix.asClass(SearchResultPresenter)
            .inject(() => ({policyRepository: diContainer.resolve('policyRepository')}))
            .singleton(),
        policyDetailPresenter: Awilix.asClass(PolicyDetailPresenter)
            .inject(() => ({policyRepository: diContainer.resolve('policyRepository')}))
            .singleton(),
        feedbackPresenter: Awilix.asClass(FeedbackPresenter)
            .inject(() => ({feedbackRepository: diContainer.resolve('feedbackRepository')}))
            .singleton(),
        searchBarViewPresenter: Awilix.asClass(SearchBarViewPresenter)
            .inject(() => ({varRepository: diContainer.resolve('varRepository')}))
            .singleton(),
    });

    return diContainer;
}

export {
    buildDiContainer as DiContainer,
};
