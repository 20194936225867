export function parseFilterTags(qWithFilters) {
    const filterObj = {
        'gs': [],
        'dtyp': [],
        'sec': [],
        'thm': [],
    };

    let filterKWordsLoc = [];
    let newKWords = qWithFilters;

    // Parse each filter tag
    for(const eachFilterField of Object.keys(filterObj)) {
        const filterMatch = [...qWithFilters.matchAll(new RegExp(`${eachFilterField}:\\s?((?!")([\\s\\w-,/]+)(?!")|"([\\s\\w-,/]+)")`, 'g'))];
        
        for(const eachfMatch of filterMatch) {
            const term = (eachfMatch[2] && eachfMatch[2].length > 0) ? eachfMatch[2] : eachfMatch[3];
            const termList = term.split(',').map((v) => v.trim());

            filterObj[eachFilterField].push(...termList);
            filterKWordsLoc.push([eachfMatch.index, eachfMatch[0].length]);
        }
    }

    // Erase filter tags from original query
    for(const [start, length] of filterKWordsLoc) {
        newKWords = newKWords.slice(0, start) + (' '.repeat(length)) + newKWords.slice(start + length);
    }

    // Remove spaces between the keywords
    newKWords = newKWords.split(' ').filter((v) => v.trim().length > 0).map((v) => v.trim()).join(' ');

    return {q: newKWords, f: filterObj};
}

export function buildSearchFilterQuery({gs, dtyp, thm, sec, tagsSep=';'}) {
    let filterQ = '';

    if(gs?.length > 0) {
        if(filterQ.length > 0) {
            filterQ += tagsSep;
        }

        filterQ += `gs:"${gs.map((v) => v.trim()).join(',')}"`;
    }
    
    if(dtyp?.length > 0) {
        if(filterQ.length > 0) {
            filterQ += tagsSep;
        }

        filterQ += `dtyp:"${dtyp.map((v) => v.trim()).join(',')}"`;
    }

    if(thm?.length > 0) {
        if(filterQ.length > 0) {
            filterQ += tagsSep;
        }

        filterQ += `thm:"${thm.map((v) => v.trim()).join(',')}"`;
    }

    if(sec?.length > 0) {
        if(filterQ.length > 0) {
            filterQ += tagsSep;
        }

        filterQ += `sec:"${sec.map((v) => v.trim()).join(',')}"`;
    }

    return filterQ;
}