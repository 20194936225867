import m from 'mithril';

// DI container
import { DiContainer } from './di';
import { getRoutes } from './routes';

// CSS imports
import 'picnic';
import './views/css/base.scss';
import './views/css/base_print.scss';

function main() {
    const di = DiContainer();

    let bodyRoot = document.body;

    //m.route.prefix = '';

    m.route(bodyRoot, '/', getRoutes(di));
}

main();