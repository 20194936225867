import m from "mithril";

import { buildSearchFilterQuery } from '../util/SearchFilterParser';

export class NetworkApiService {
    constructor({apiPrefix}) {
        this.apiPrefix = apiPrefix;
    }

    // Var APIs
    async getVar(varName) {
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/var/:varName`,
            params: {varName: varName},
            background: true,
        });
    }

    // Feedback APIs
    async sendFeedback({name, email, phone, type, comment, suggestion}) {
        return await m.request({
            method: 'POST',
            url: `${this.apiPrefix}/feedback/create`,
            body: {name, email, phone, type, comment, suggestion},
            background: true,
        });
    }

    // Policy APIs
    async getPolicyCount(searchFilters = {}) {
        const filterQ = buildSearchFilterQuery(searchFilters);

        // Geoscope
        const gsQ = searchFilters['gs']?.at(0) ?? 'I';
        
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/policy/count`,
            params: {f: filterQ, gs: gsQ},
            background: true,
        });
    }

    async getPolicyWithId(id) {
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/policy/:id`,
            params: {id: id},
            background: true,
        });
    }
    
    async getPolicyWithKwords(kwords, {searchFilters = {}, pageFilters = {offset=0, limit=10, sort = '-rel'} = {}}) {
        const filterQ = buildSearchFilterQuery(searchFilters);

        // Geoscope
        const gsQ = searchFilters['gs']?.at(0) ?? 'I';

        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/policy/search`,
            params: {q: kwords, gs: gsQ, f: filterQ, offset: pageFilters.offset, limit: pageFilters.limit, sort: pageFilters.sort},
            background: true,
        });
    }

    async getPolicyByFilters(searchFilters, {offset=0, limit=10, sort='-rel'}={}) {
        const filterQ = buildSearchFilterQuery(searchFilters);

        // Geoscope
        const gsQ = searchFilters['gs']?.at(0) ?? 'I';

        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/policy/search`,
            params: {f: filterQ, gs: gsQ, offset: offset, limit: limit, sort: sort},
            background: true,
        });
    }

    async getRandomPolicy(numPolicies) {
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/policy/search`,
            params: {q: 'covid-19', limit: numPolicies},
            background: true,
        });
    }

    async generateSbook(kwords, {searchFilters = {}} = {}) {
        const filterQ = buildSearchFilterQuery(searchFilters);

        // Geoscope
        const gsQ = searchFilters['gs']?.at(0) ?? 'I';

        return await m.request({
            method: 'POST',
            url: `${this.apiPrefix}/sbook/generate`,
            params: {q: kwords, gs: gsQ, f: filterQ},
            background: true,
        });
    }

    async getSbookStatus(sbookId) {
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/sbook/${sbookId}/status`,
            background: true,
        });
    }

    async fetchSbookFile(sbookId) {
        return await m.request({
            method: 'GET',
            url: `${this.apiPrefix}/sbook/${sbookId}/file`,
            responseType: 'blob',
            background: true,
        });
    }
}