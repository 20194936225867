import { Policy } from '../models/Policy';
import { Error } from '../models/Error';

export class PolicyRepository {
    constructor({dataSource}) {
        this.dataSource = dataSource;
    }

    async getPolicyCount(searchFilters) {
        try {
            const res = await this.dataSource.getPolicyCount(searchFilters);

            return res.count;
        }
        catch(err) {
            console.log(err);

            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getPolicyWithId(id) {
        try {
            const res = await this.dataSource.getPolicyWithId(id);

            return new Policy(
                res.id,
                res.title,
                {
                    'doctype': res.doctype,
                    'datePublished': res.date,
                    'link': res.link,
                    'sectors': res.sectors,
                    'themes': res.themes,
                    'summary': res.summary,
                },
            );
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getPolicyByFilters(searchFilters, pageFilters={}) {
        try {
            const res = await this.dataSource.getPolicyByFilters(searchFilters, pageFilters);

            return res.data.map((v) => {
                return new Policy(
                    v.id,
                    v.title,
                    {
                        'datePublished': v.date,
                        'link': v.link,
                        'sectors': v.sectors,
                        'themes': v.themes,
                        'summary': v.summary.slice(0, 500) + '...',
                    },
                );
            });
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getPolicyWithKwords(kwords, {searchFilters = {}, pageFilters = {}}) {
        try {
            // Parse filter tags
            const res = await this.dataSource.getPolicyWithKwords(kwords, {searchFilters, pageFilters});

            return res.data.map((v) => {
                return new Policy(
                    v.id,
                    v.title,
                    {
                        'datePublished': v.date,
                        'link': v.link,
                        'sectors': v.sectors,
                        'themes': v.themes,
                        'summary': v.summary.slice(0, 500) + '...',
                    },
                );
            });
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getRandomPolicy(numPolicies) {
        try {
            const res = await this.dataSource.getRandomPolicy(numPolicies);
            
            return res.data.map((v) => {
                return new Policy(
                    v.id,
                    v.title,
                    {   
                        'doctype': v.doctype,
                        'datePublished': v.date,
                        'link': v.link,
                        'sectors': v.sectors,
                        'themes': v.themes,
                        'summary': v.summary.slice(0, 500) + '...',
                        'justification': v.justification.slice(0, 500) + '...',
                    },
                );
            });
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async generateSbookFromPolicies(kwords, {searchFilters = {}}) {
        try {
            const res = await this.dataSource.generateSbook(kwords, {searchFilters});

            return res;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getGenerateSbookStatus(sbookId) {
        try {
            const res = await this.dataSource.getSbookStatus(sbookId);

            return res;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async fetchSbookFile(sbookId) {
        try {
            const res = await this.dataSource.fetchSbookFile(sbookId);

            return res;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }
}