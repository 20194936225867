import * as Rx from 'rxjs';

export class FeedbackPresenter {
    constructor({feedbackRepository}) {
        this.feedbackRepository = feedbackRepository;
    }

    async sendFeedback(feedback) {
        const feedbackObj = await this.feedbackRepository.sendFeedback(feedback);

        return feedbackObj;
    }
}