import * as Rx from 'rxjs';

export class PolicyDetailPresenter {
    constructor({policyRepository}) {
        this.policyRepository = policyRepository;
        this.policyObj = {};
    }

    async fetchPolicy(policyId) {
        this.policyObj = await this.policyRepository.getPolicyWithId(policyId);
        this.policyObj.yearPublished = new Date(this.policyObj.datePublished).getFullYear();

        return this.policyObj;
    }
}