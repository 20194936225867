import { Error } from '../models/Error';

export class FeedbackRepository {
    constructor({dataSource}) {
        this.dataSource = dataSource;
    }

    async sendFeedback(feedback) {
        try {
            const res = await this.dataSource.sendFeedback(feedback);

            return res;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }
}