import m from 'mithril';

import { parseFilterTags } from '../util/SearchFilterParser';

export class SearchResultPresenter {
    constructor({policyRepository}) {
        this.policyRepository = policyRepository;
        this.searchResults = [];

        this.currentQ = '';
        this.currentFilters = {};
        this.currentOffset = 0;
        this.currentLimit = 10;
    }

    async doInitSearch(searchObj) {
        const {q: newKWords, f: searchFilters} = parseFilterTags(searchObj.q);

        this.currentQ = newKWords;
        this.currentFilters = searchFilters;
        this.currentOffset = searchObj.offset ?? 0;
        this.currentLimit = searchObj.limit ?? 10;

        const searchResults = await this.policyRepository.getPolicyWithKwords(
            this.currentQ,
            {
                searchFilters: this.currentFilters,
                pageFilters: {offset: this.currentOffset, limit: this.currentLimit},
            });

        this.searchResults = searchResults.map((v) => {
            return {
                'id': v.id,
                'title': v.title,
                'datePublished': v.datePublished,
                'sectors': v.sectors,
                'themes': v.themes,
                'link': m.buildPathname('/policy/:id', {id: v.id}),
                'summary': v.summary,
            };
        });

        return this.searchResults;
    }

    async loadNext() {
        let searchResults = await this.policyRepository.getPolicyWithKwords(
            this.currentQ,
            {
                searchFilters: this.currentFilters,
                pageFilters: {offset: this.currentOffset + this.currentLimit, limit: this.currentLimit},
            },
        );

        searchResults = searchResults.map((v) => {
            return {
                'id': v.id,
                'title': v.title,
                'datePublished': v.datePublished,
                'sectors': v.sectors,
                'themes': v.themes,
                'link': m.buildPathname('/policy/:id', {id: v.id}),
                'summary': v.summary,
            };
        });

        this.currentOffset += this.currentLimit;

        return searchResults;
    }

    async generateSbookFromPolicies() {
        const maxTimeout = 60000; // 1 minute
        let currentTimeLapsed = 0;

        let { status, id: sbookId } = await this.policyRepository.generateSbookFromPolicies(
            this.currentQ,
            {
                searchFilters: this.currentFilters,
            },
        );

        while(status != 'generated' && currentTimeLapsed < maxTimeout) {
            const statusObj = await this.policyRepository.getGenerateSbookStatus(sbookId);
            status = statusObj['status'];

            await new Promise(r => setTimeout(r, 5000));
            currentTimeLapsed += 5000;
        }

        return sbookId;
    }

    async fetchSbookFile(sbookId) {
        const content = await this.policyRepository.fetchSbookFile(sbookId);
        return content;
    }
}