/** Represents a policy */
export class Policy {
    /**
     * Create a policy
     * @param {number} id - Integer internal ID of the policy
     * @param {string} title - Title of the policy
     * @param {Object} [details={}] - Other optional pertinent details of the policy
     * @param {string} [details.serial=''] - Shorthand name of the policy
     * @param {string} [details.doctype=''] - Document type of the policy
     * @param {date} [details.datePublished=''] - Date of publication of the policy
     * @param {String[]} [details.sectors=[]] - Sectors where the policy is applicable
     * @param {String[]} [details.themes=[]] - Thematic area that the policy is about
     * @param {Object} [details.link=''] - Network link
     * @param {String} [details.link.name=''] - Name of link pointing to the full text of the policy
     * @param {String} [details.link.url=''] - Network path (URL) of link pointing to the full text of the policy
     * @param {string} [details.summary=''] - Notes or summary related to the policy
     * @param {string} [details.justification=''] - Justification related to the policy
     */
    constructor(id, title, {serial = '', doctype = '', datePublished = '', sectors = [], themes = [], link = {name: '', url: ''}, summary = '', justification = ''} = {}) {
        this.id = id;
        this.title = title;
        this.serial = serial;
        this.doctype = doctype;
        this.datePublished = datePublished;
        this.sectors = sectors;
        this.themes = themes;
        this.link = {
            'name': link.name,
            'url': link.url,
        };
        this.summary = summary;
        this.justification = justification;
    }

    /**
     * Add a sector
     * @param {string} sector - The sector to add
     */
    addSector(sector) {
        this.sectors.push(sector);
    }

    /**
     * Remove a sector
     * @param {string} sector - The sector to remove
     */
    removeSector(sector) {
        this.sectors.splice(this.sectors.indexOf(sector), 1);
    }

    /**
     * Add a thematic area
     * @param {string} theme - The thematic area to add 
     */
    addTheme(theme) {
        this.themes.push(theme);
    }

    /**
     * Remove a thematic area
     * @param {string} theme - The thematic area to remove
     */
    removeTheme(theme) {
        this.themes.splice(this.themes.indexOf(theme), 1);
    }
}