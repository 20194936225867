import { Error } from '../models/Error';

export class VarRepository {
    constructor({dataSource}) {
        this.dataSource = dataSource;
    }

    async getSectors() {
        try {
            const res = await this.dataSource.getVar('policy_sectors');

            if(res.type != 'array_kvpair') {
                throw {
                    title: 'Unexpected Variable Type',
                    status: 400,
                    detail: `Expected variable type is array_kvpair but found ${res.type}`
                };
            }

            return res.data;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getThemes() {
        try {
            const res = await this.dataSource.getVar('policy_themes');

            if(res.type != 'array_kvpair') {
                throw {
                    title: 'Unexpected Variable Type',
                    status: 400,
                    detail: `Expected variable type is array_kvpair but found ${res.type}`
                };
            }

            return res.data;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getDoctypes() {
        try {
            const res = await this.dataSource.getVar('policy_doctypes');

            if(res.type != 'array_kvpair') {
                throw {
                    title: 'Unexpected Variable Type',
                    status: 400,
                    detail: `Expected variable type is array_kvpair but found ${res.type}`
                };
            }

            return res.data;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }

    async getGeoscopes() {
        try {
            const res = await this.dataSource.getVar('policy_geoscopes');

            if(res.type != 'array_kvpair') {
                throw {
                    title: 'Unexpected Variable Type',
                    status: 400,
                    detail: `Expected variable type is array_kvpair but found ${res.type}`
                };
            }

            return res.data;
        }
        catch(err) {
            return new Error(
                err.title,
                err.status,
                err.detail,
            );
        }
    }
}