import m from 'mithril';

export class IndexPresenter {
    constructor({policyRepository, varRepository}) {
        this.policyRepository = policyRepository;
        this.varRepository = varRepository;

        this.featuredPolicies = [];
        this.sectorCounts = [];
    }

    async fetchPoliciesBySector(sector, pageFilters={}) {
        const policies = await this.policyRepository.getPolicyByFilters({sec: [sector]}, pageFilters);

        return policies.map((v) => {
            return {
                'id': v.id,
                'title': v.title,
                'datePublished': v.datePublished,
                'sectors': v.sectors,
                'themes': v.themes,
                'link': m.buildPathname('/policy/:id', {id: v.id}),
            }
        });
    }

    async fetchRandomPolicy(count) {
        const searchResults = await this.policyRepository.getRandomPolicy(count);

        this.featuredPolicies = searchResults.map((v) => {
            return {
                'id': v.id,
                'title': v.title,
                'datePublished': v.datePublished,
                'sectors': v.sectors,
                'themes': v.themes,
                'link': m.buildPathname('/policy/:id', {id: v.id}),
                'summary': v.summary,
            };
        });

        return this.featuredPolicies;
    }

    async fetchSectorCounts() {
        const sectors = await this.varRepository.getSectors();
        
        const sectorCounts = await Promise.all(Object.entries(sectors).map(async ([k, eachSectorName]) => {
            const count = await this.policyRepository.getPolicyCount({sec: [eachSectorName]});

            return {
                id: k,
                name: eachSectorName,
                cardClasses: 'bg-color-yellow',
                iconSrc: 'static/icons/sector_phys.svg',
                count: count,
            };
        }));

        // Sort in decreasing number of policies
        this.sectorCounts = sectorCounts.sort((a, b) => a.count < b.count);

        return this.sectorCounts;
    }
}